import { useQuery } from "urql";
const OriganizationTypes = `
query MyQuery($searchTerm: String!) {
        chain_partners(where: {company_name: {_ilike: $searchTerm}},offset:0, limit: 10) {
          id
          phone
          company_name
          city
          state
          email
          address
        }
      }
      
  
`;

export function useChainPartnersBySearch({ searchTerm }) {
  const result = useQuery({
    query: OriganizationTypes,
    variables: { searchTerm: `%${searchTerm}%` },
  });

  return result;
}
