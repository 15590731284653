import React from "react";
import { useEquipmentRequests } from "../../hooks";
import { Spinner, Button } from "@chakra-ui/core";
import { EquipmentRequestListItem, Paginate } from "../../components";

export function OrganizationEquipmentRequestsList({ filter, setFilter }) {
  const [result, refetch] = useEquipmentRequests(filter);

  if (result.fetching) {
    return <Spinner />;
  }

  if (result.error) {
    return (
      <div>
        Something went wrong. Please Retry
        <Button onClick={refetch}>Retry</Button>
      </div>
    );
  }

  if (result.data) {
    const { equipment_requests, equipment_requests_aggregate } = result.data;
    return (
      <div>
        <div>
          Total records {equipment_requests_aggregate.aggregate.totalCount}{" "}
        </div>
        {equipment_requests.map((req) => {
          return (
            <div>
              <EquipmentRequestListItem key={req.id} item={req} />
            </div>
          );
        })}
        <Paginate
          offset={filter.offset}
          limit={filter.limit}
          total={equipment_requests_aggregate.aggregate.totalCount}
          onPageChange={(selected) => {
            setFilter({ ...filter, offset: filter.limit * selected });
          }}
        />
      </div>
    );
  }
  return null;
}
