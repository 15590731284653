import React, { useState, useEffect } from "react";
import { useChainPartnersBySearch } from "../../hooks";
import { Input, Button, Box, Heading } from "@chakra-ui/core";

function SearchItem({ item, onSelect, isSelected }) {
  return (
    <Box
      shadow="md"
      borderWidth="1px"
      justifyContent="space-between"
      display="flex"
      padding={10}
    >
      <Box>
        <Heading fontSize="xl">{item.company_name}</Heading>
        <Box>{item.address + ", " + item.state + ", " + item.city}</Box>
        <Box>{item.phone}</Box>
      </Box>
      <Button
        onClick={() => onSelect(item)}
        variantColor={isSelected ? "teal" : "blueGray"}
      >
        {isSelected ? "Selected" : "Select"}
      </Button>
    </Box>
  );
}

export function ChainPartnerSearchAndSelect({ onSelect, selected }) {
  const [searchTerm, setSearchTerm] = useState();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [chainPartnerSearchResults] = useChainPartnersBySearch({ searchTerm });
  const handleSelect = (item) => {
    onSelect(item);
    setSearchTerm(item.company_name);
    setShowSearchBox(false);
  };

  useEffect(() => {
    if (!selected) {
      setSearchTerm("");
    }
  }, [selected]);

  const renderSearchResults = () => {
    if (chainPartnerSearchResults.data) {
      return chainPartnerSearchResults.data.chain_partners.map((item) => {
        return (
          <SearchItem
            key={item.key}
            item={item}
            onSelect={handleSelect}
            isSelected={selected == item.id}
          />
        );
      });
    }
  };

  return (
    <Box position="relative">
      <Input
        type={"text"}
        value={searchTerm}
        placeholder="Search your company"
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setShowSearchBox(true);
        }}
      />
      <Box
        overflowY="scroll"
        maxHeight={400}
        position="absolute"
        zIndex={100}
        background="white"
        width="100%"
      >
        {showSearchBox && renderSearchResults()}
      </Box>
    </Box>
  );
}
