import React from "react";
import { Select, Flex } from "@chakra-ui/core";

export function EquipmentRequestFilter({ filter, setFilter }) {
  return (
    <div>
      <Flex align="center">
        <Select
          placeholder="Select status"
          value={filter.status}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        >
          <option value="open">open</option>
          <option value="closed">closed</option>
        </Select>
      </Flex>
    </div>
  );
}
