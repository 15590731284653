import React from "react";
import { useChainPartnersByEquipmentId } from "../../hooks";
import { Spinner } from "@chakra-ui/core";
import { ChainPartnerEquipmentListItem } from "../../components";

function ChainPartnerEquipmentsList({ id }) {
  const [result, refetch] = useChainPartnersByEquipmentId({ id });
  if (result.fetching) {
    return <Spinner />;
  }

  if (result.error) {
    return "error. try again";
  }

  if (result.data) {
    return result.data.chain_partner_equipments.map((item) => {
      return <ChainPartnerEquipmentListItem item={item} />;
    });
  }
}

export { ChainPartnerEquipmentsList };
