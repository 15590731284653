import { useQuery } from "urql";
const EquipmentsHook = `
query EquipmentsQuery {
  equipments {
    id
    name
  }
}
`;

export function useEquipments() {
  const result = useQuery({
    query: EquipmentsHook,
  });

  return result;
}
