import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useEquipments } from "../../hooks";
import {
  FormControl,
  FormLabel,
  Select,
  Button,
  useToast,
  Divider,
} from "@chakra-ui/core";
import { RegisterChainPartner } from "../../containers";
import { ChainPartnerSearchAndSelect } from "../ChainPartnerSearchAndSelect";
import { useMutation } from "urql";
import { Box } from "@chakra-ui/core";

const insertChainPartnerEquipmentMutation = `
mutation MyQuery($chainPartnerEquipment: chain_partner_equipments_insert_input!) {
  insert_chain_partner_equipments(objects: [$chainPartnerEquipment]) {
    returning {
      id
    }
  }
}  
`;

export function ChainPartnerEquipments() {
  const toast = useToast();
  const [result] = useEquipments();
  const [insertMutationResult, insertMutation] = useMutation(
    insertChainPartnerEquipmentMutation
  );

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const selectedCompanyId = watch("chain_partner_id");

  const onSubmit = (data) => {
    insertMutation({ chainPartnerEquipment: data });
  };

  useEffect(() => {
    if (insertMutationResult.data) {
      toast({
        title: "Thank you!",
        description:
          "Equipment supply request has been submitted. Your supply request will be provided to organization authorities.",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
      reset();
    } else if (insertMutationResult.error) {
      toast({
        title: "Something went wrong",
        description: insertMutationResult.error.message,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  }, [insertMutationResult, toast, reset]);

  return (
    <>
      <Box pb={10}>
        <FormControl pb={5}>
          <FormLabel isRequired htmlFor="searchOrganization">
            Search and Select your Company
          </FormLabel>
          <ChainPartnerSearchAndSelect
            onSelect={(company) => {
              setValue("chain_partner_id", company.id);
            }}
            selected={selectedCompanyId}
          />
          {errors.chain_partner_id ? (
            <Box color="redVivid.800">
              Please select your organization from above Search box
            </Box>
          ) : null}
        </FormControl>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            hidden
            name="chain_partner_id"
            ref={register({ required: true })}
          />
          <FormControl>
            <FormLabel isRequired htmlFor="equipment_id">
              Select Equipment
            </FormLabel>
            <Select
              id="equipment_id"
              name="equipment_id"
              placeholder="Select Equipment"
              ref={register({ required: true })}
            >
              {result.data
                ? result.data.equipments.map((equipment) => {
                    return (
                      <option key={equipment.id} value={equipment.id}>
                        {equipment.name}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          <Button
            mt={4}
            variantColor="teal"
            isLoading={insertMutationResult.fetching}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
      <Box
        display="flex"
        color="blueGray.900"
        textTransform="uppercase"
        justifyContent="center"
      >
        OR
      </Box>
      <Divider />
      <Box
        display="flex"
        fontWeight="bold"
        color="lightBlueVivid.900"
        textTransform="capitalize"
        justifyContent="center"
      >
        If you can't find your organization, please fill the below form to
        register
      </Box>
      <Divider />
      <RegisterChainPartner />
    </>
  );
}
