import React, { useEffect } from "react";
import { Switch, Flex, FormLabel, Spinner, useToast } from "@chakra-ui/core";
import { useMutation } from "urql";

const mutation = `
mutation MyMutation($status: String!, $id:Int!) {
    update_equipment_requests(where: {id: {_eq: $id}}, _set: {status: $status}) {
      returning {
        id
        status
      }
    }
  }
  
`;

export function ToggleEquipmentRequestStatus({ status, onToggle, id }) {
  const [result, mutate] = useMutation(mutation);
  const toast = useToast();
  const handleToggle = () => {
    mutate({
      id,
      status: status === "open" ? "closed" : "open",
    });
    // onToggle();
  };

  useEffect(() => {
    if (result.data) {
      const updatedStatus =
        result.data.update_equipment_requests.returning[0].status;
      toast({
        title: "Request marked as " + updatedStatus,
        description: `Equipment request has been marked as ${updatedStatus}. Change above filter to ${updatedStatus} to revert it.`,
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    } else if (result.error) {
      toast({
        title: "Something went wrong",
        description: result.error.message,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  }, [result, toast]);

  return (
    <Flex justify="center" align="center">
      <FormLabel htmlFor="email-alerts"></FormLabel>
      {result.fetching ? (
        <Spinner />
      ) : (
        <Switch
          id="email-alerts"
          isChecked={status === "open" ? true : false}
          onChange={handleToggle}
        />
      )}
    </Flex>
  );
}
