import React, { useEffect } from "react";
import { ChainPartnerRegistration } from "../../components";
import { useMutation } from "urql";
import { useToast } from "@chakra-ui/core";

const addChainPartnerMutation = `
mutation MyQuery($chainPartner: chain_partners_insert_input!) {
  insert_chain_partners(objects: [$chainPartner]) {
      returning {
        id
      }
    }
}  
`;

export function RegisterChainPartner() {
  const toast = useToast();
  const [addChainPartnerResult, addChainPartner] = useMutation(
    addChainPartnerMutation
  );

  useEffect(() => {
    if (addChainPartnerResult.data) {
      toast({
        title: "Registered",
        description: "You can now create equipment supply requests.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else if (addChainPartnerResult.error) {
      toast({
        title: "Something went wrong",
        description: addChainPartnerResult.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [addChainPartnerResult, toast]);

  const handleSubmit = (data) => {
    addChainPartner({
      chainPartner: data,
    });
  };

  return (
    <ChainPartnerRegistration
      onSubmit={handleSubmit}
      isSubmitting={addChainPartnerResult.fetching}
    />
  );
}
