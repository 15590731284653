import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider, Spinner, CSSReset, theme, Box } from "@chakra-ui/core";
import { ChainPartnerEquipments, EquipmentRequest } from "./components";
import {
  RegisterOrganization,
  RegisterChainPartner,
  Dashboard,
} from "./containers";
import { useAuth0 } from "./react-auth0-spa";
import NavBar from "./components/shared/Navbar";
import colors from "./config/colors";
import { links } from "./utils";
const customTheme = {
  ...theme,
  colors,
};

function App() {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <CSSReset />
        <NavBar />
        <Box p={10} pt="5rem">
          <PublicRoutes />
          <AuthenticatedRoutes />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

const PublicRoutes = () => {
  return (
    <>
      <Route path={links.chainPartnersEquipment} exact>
        <ChainPartnerEquipments />
      </Route>
      <Route path={links.hospitalRequests} exact>
        <EquipmentRequest />
      </Route>
    </>
  );
};

const AuthenticatedRoutes = () => {
  const data = useAuth0();
  // if (data.loading) {
  //   return <Spinner />;
  // }

  if (data.isAuthenticated) {
    return (
      <>
        <Route path={links.dashboard} exact>
          <Dashboard />
        </Route>
      </>
    );
  }

  return null;
};

export default App;
