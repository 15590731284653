import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/core";
import { getStates, getCitiesByStateId } from "../../../utils";

export function FormAddress({ register, watch, errors }) {
  const state = watch("state");

  return (
    <>
      <FormControl pb={5}>
        <FormLabel isRequired htmlFor="address">
          Address
        </FormLabel>

        <Textarea
          name="address"
          id="address"
          ref={register({ required: true })}
          placeholder="enter address here"
        ></Textarea>
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired htmlFor="state">
          State
        </FormLabel>
        <Select
          id="state"
          name="state"
          placeholder="select state"
          defaultValue="unselected"
          ref={register({ required: true })}
        >
          {getStates().map((state, index) => {
            return (
              <option value={index} key={index}>
                {state}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired htmlFor="city">
          City
        </FormLabel>
        <Select
          id="city"
          name="city"
          placeholder="select City"
          ref={register({ required: true })}
        >
          {getCitiesByStateId(state).map((city) => {
            return (
              <option key={city} value={city}>
                {city}
              </option>
            );
          })}
        </Select>
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isInvalid={errors.pincode} isRequired htmlFor="pincode">
          Pincode
        </FormLabel>
        <Input
          id="pincode"
          type="pincode"
          placeholder="pincode"
          name="pincode"
          ref={register}
        />
      </FormControl>
    </>
  );
}
