import React, { useState } from "react";
import { useOrganizationBySearch } from "../../hooks";
import { Input, Button, Box, Heading } from "@chakra-ui/core";
import { useEffect } from "react";

function SearchItem({ item, onSelect, isSelected }) {
  return (
    <Box
      shadow="md"
      borderWidth="1px"
      justifyContent="space-between"
      display="flex"
      padding={10}
    >
      <Box>
        <Heading fontSize="xl">{item.name}</Heading>
        <Box>{item.address + ", " + item.state + ", " + item.city}</Box>
        <Box>{item.phone}</Box>
      </Box>
      <Box>
        <Button
          onClick={() => onSelect(item)}
          variantColor={isSelected ? "teal" : "blueGray"}
        >
          {isSelected ? "Selected" : "Select"}
        </Button>
      </Box>
    </Box>
  );
}

export function OrganizationSearchAndSelect({ onSelect, selected }) {
  const [searchTerm, setSearchTerm] = useState();
  const [organizationSearchResults] = useOrganizationBySearch({ searchTerm });
  const [showSearchBox, setShowSearchBox] = useState(false);

  const handleSelect = (item) => {
    onSelect(item);
    setSearchTerm(item.name);
    setShowSearchBox(false);
  };

  useEffect(() => {
    if (!selected) {
      setSearchTerm("");
    }
  }, [selected]);

  const renderSearchResults = () => {
    if (organizationSearchResults.data) {
      return organizationSearchResults.data.organizations.map((item) => {
        return (
          <SearchItem
            item={item}
            onSelect={() => {
              handleSelect(item);
            }}
            isSelected={selected == item.id}
          />
        );
      });
    }
  };

  return (
    <Box position="relative">
      <Input
        type={"text"}
        value={searchTerm}
        placeholder="Search and Select your organization"
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setShowSearchBox(true);
        }}
      />
      <Box
        overflowY="scroll"
        maxHeight={400}
        position="absolute"
        zIndex={100}
        background="white"
        width="100%"
      >
        {showSearchBox && renderSearchResults()}
      </Box>
    </Box>
  );
}
