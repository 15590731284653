import React from "react";
import { Stack, Box, Heading, Text } from "@chakra-ui/core";
import { useState } from "react";
import { ChainPartnerEquipmentsList } from "../../../containers";
import { ToggleEquipmentRequestStatus } from "../../ToggleEquipmentRequestStatus";

function Feature({ organization, equipment, quantity, dueData, status, id }) {
  const [showChainPartners, setShowChainPartners] = useState(false);
  return (
    <div onClick={() => setShowChainPartners(!showChainPartners)}>
      <Box p={5} shadow="md" borderWidth="1px">
        <Heading fontSize="xl">{organization.name}</Heading>
        <Text mt={4}>{equipment.name}</Text>
        <Text mt={4}>{quantity}</Text>
        <Text mt={4}>{dueData}</Text>
        <ToggleEquipmentRequestStatus status={status} id={id} />
      </Box>
      {showChainPartners ? (
        <ChainPartnerEquipmentsList id={equipment.id} />
      ) : null}
    </div>
  );
}

export function EquipmentRequestListItem({ item }) {
  const { equipment, organization, status, quantity, due_date } = item;

  return (
    <Stack spacing={2}>
      <Feature
        organization={organization}
        equipment={equipment}
        status={status}
        quantity={quantity}
        id={item.id}
        dueData={due_date}
      />
    </Stack>
  );
}
