import React from "react";
import { HospitalRegistration } from "../../components";
import { useMutation } from "urql";
import { useToast } from "@chakra-ui/core";
import { useEffect } from "react";

const addOrganizationMutation = `
mutation MyQuery($hospital: organizations_insert_input!) {
    insert_organizations(objects: [$hospital]) {
      returning {
        id
      }
    }
}  
`;

export function RegisterOrganization(props) {
  const toast = useToast();

  const [addOrganizationResult, addOrganization] = useMutation(
    addOrganizationMutation
  );

  useEffect(() => {
    if (addOrganizationResult.data) {
      toast({
        title: "Organization registered",
        description: "You can now make equipment requests.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else if (addOrganizationResult.error) {
      toast({
        title: "Something went wrong",
        description: addOrganizationResult.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [addOrganizationResult, toast]);

  const handleSubmit = (data) => {
    addOrganization({
      hospital: data,
    });
  };

  return (
    <HospitalRegistration
      onSubmit={handleSubmit}
      isSubmitting={addOrganizationResult.fetching}
    />
  );
}
