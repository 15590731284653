import { createClient } from "urql";
export const API_URL = "https://covidcare.herokuapp.com/v1/graphql";

let token;

export const setToken = (t) => {
  token = t;
};

export const getToken = () => token;

export const client = createClient({
  url: API_URL,
  fetchOptions: () => {
    const token = getToken();
    return {
      headers: {
        "x-hasura-admin-secret": "covidcare_admin",
        Authorization: `Bearer ${token}`,
      },
    };
  },
});

export * from "./state_city";

export let links = {
  RegisterOrganization: "/organization/register",
  chainPartnersEquipment: "/chain-partners/equipments/create",
  registerChainPartner: "/chain-partners/register",
  hospitalRequests: "/equipments-request/create",
  dashboard: "/",
};
