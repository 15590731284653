import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useEquipments } from "../../hooks";
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  Select,
  useToast,
} from "@chakra-ui/core";
import { RegisterOrganization } from "../../containers";
import { OrganizationSearchAndSelect } from "../OrganizationSearchAndSelect";
import { useMutation } from "urql";
import DatePicker from "react-datepicker";
import { Box } from "@chakra-ui/core";
import { Stack } from "@chakra-ui/core";
import { Divider } from "@chakra-ui/core";

const insertEquipmentRequestMutation = `
mutation MyQuery($equipmentRequest: equipment_requests_insert_input!) {
  insert_equipment_requests(objects: [$equipmentRequest]) {
    returning {
      id
    }
  }
}  
`;

export function EquipmentRequest() {
  const [result] = useEquipments();
  const toast = useToast();

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm({
    defaultValues: {
      due_date: new Date(),
    },
  });

  const selectedOrganizationId = watch("organization_id");
  const dueDate = watch("due_date");

  const [insertMutationResult, insertMutation] = useMutation(
    insertEquipmentRequestMutation
  );

  const onSubmit = (data) => {
    const req = {
      equipmentRequest: { ...data, due_date: new Date(data.due_date) },
    };
    insertMutation(req);
  };

  useEffect(() => {
    if (insertMutationResult.data) {
      toast({
        title: "We'll get back to you soon.",
        description: "Equipment request has been submitted.",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
      reset();
    } else if (insertMutationResult.error) {
      toast({
        title: "Something went wrong",
        description: insertMutationResult.error.message,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  }, [insertMutationResult, toast, reset]);

  return (
    <>
      <Box pb={10}>
        <FormControl pb={5}>
          <FormLabel isRequired htmlFor="searchOrganization">
            Search and Select your organization
          </FormLabel>
          <OrganizationSearchAndSelect
            id="searchOrganization"
            onSelect={(company) => {
              setValue("organization_id", company.id);
            }}
            selected={selectedOrganizationId}
          />
          {errors.organization_id ? (
            <Box color="redVivid.800">
              Please select an organization from above Search box
            </Box>
          ) : null}
        </FormControl>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="number"
            hidden
            name="organization_id"
            ref={register({
              required: true,
            })}
          />

          {console.log("errors ", errors.organization_id)}
          <FormControl pb={5}>
            <FormLabel isRequired htmlFor="equipment_id">
              Select Equipment
            </FormLabel>
            <Select
              id="equipment_id"
              name="equipment_id"
              placeholder="Select Equipment"
              ref={register({ required: true })}
            >
              {result.data
                ? result.data.equipments.map((equipment) => {
                    return (
                      <option key={equipment.id} value={equipment.id}>
                        {equipment.name}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>

          <FormControl pb={5}>
            <FormLabel
              isRequired
              isInvalid={errors.quantity}
              htmlFor="quantity"
            >
              Quantity
            </FormLabel>
            <Input
              id="quantity"
              type="number"
              placeholder="Quantity"
              name="quantity"
              ref={register({ required: true })}
            />
          </FormControl>

          <FormControl pb={5}>
            <FormLabel
              isRequired
              isInvalid={errors.due_date}
              htmlFor="due_date"
            >
              Due date
            </FormLabel>
            <DatePicker
              className="datepicker-input"
              onChange={(date) => setValue("due_date", date)}
              placeholder="Due date"
              selected={new Date(dueDate)}
              minDate={new Date()}
              id="due_date"
            />
            <input
              hidden
              // type="date"
              name="due_date"
              ref={register({ required: true })}
            />
          </FormControl>
          <Button
            mt={4}
            variantColor="teal"
            isLoading={insertMutationResult.fetching}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
      <Box
        display="flex"
        color="blueGray.900"
        textTransform="uppercase"
        justifyContent="center"
      >
        OR
      </Box>
      <Divider />
      <Box
        display="flex"
        fontWeight="bold"
        color="lightBlueVivid.900"
        textTransform="capitalize"
        justifyContent="center"
      >
        If you can't find your organization, please fill the below form to
        register
      </Box>
      <Divider />
      <RegisterOrganization />
    </>
  );
}
