import React from "react";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Input, Select, Button } from "@chakra-ui/core";
import { FormAddress } from "../shared";

export function ChainPartnerRegistration({ onSubmit, isSubmitting }) {
  const { register, handleSubmit, errors, watch } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl pb={5}>
        <FormLabel
          isRequired
          isInvalid={errors.company_name}
          htmlFor="company_name"
        >
          Company Name
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter Company name"
          name="company_name"
          id="company_name"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.email} htmlFor="email">
          Email
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter email"
          name="email"
          id="email"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel
          isRequired
          isInvalid={errors.first_name}
          htmlFor="first_name"
        >
          Firstname
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter firstname"
          name="first_name"
          id="first_name"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.last_name} htmlFor="last_name">
          Lastname
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter lastname"
          name="last_name"
          id="last_name"
          ref={register({ required: true })}
        />
      </FormControl>
      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.phone} htmlFor="phone">
          Phone
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter phone"
          name="phone"
          id="phone"
          ref={register({ required: true })}
        />
      </FormControl>
      <FormControl pb={5}>
        <FormLabel htmlFor="website">Website</FormLabel>
        <Input
          type="text"
          placeholder="Enter website"
          name="website"
          id="website"
          ref={register}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired htmlFor="type">
          Select type
        </FormLabel>
        <Select
          id="type"
          name="type"
          placeholder="Select Type"
          ref={register({ required: true })}
        >
          <option value="supplier">Supplier</option>
          <option value="manufacturer">Manufacturer</option>
        </Select>
      </FormControl>

      <FormAddress register={register} errors={errors} watch={watch} />

      <Button
        mt={4}
        variantColor="teal"
        isLoading={isSubmitting}
        type="submit"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </form>
  );
}
