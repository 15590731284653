import { useQuery } from "urql";
const OriganizationTypes = `
query MyQuery($searchTerm: String!) {
    organizations(where: {name: {_ilike: $searchTerm}}, offset:0, limit: 10) {
        id
        name
        address
        city
        phone
        state
        organization_type {
          id
          name
        }
      }
  }
  
`;

export function useOrganizationBySearch({ searchTerm }) {
  const result = useQuery({
    query: OriganizationTypes,
    variables: { searchTerm: `%${searchTerm}%` },
  });

  return result;
}
