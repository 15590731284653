import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "urql";
import { client } from "./utils";
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import config from "./auth_config.json";
import "react-datepicker/dist/react-datepicker.css";

let { FormControl } = require("@chakra-ui/core");

FormControl = (props) => {
  return <FormControl {...props} pb={10} />;
};

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// const theme = useTheme();
// console.log(theme);
ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={"http://localhost:3000/callback"}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider value={client}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
