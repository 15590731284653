import { useQuery } from "urql";

const EquipmentRequestHook = `
  query EquipmentRequestQuery($offset: Int!, $limit: Int!, $status: String!) {
    equipment_requests_aggregate(where: {status: {_eq: $status}}) {
      aggregate {
        totalCount: count
      }
    }
    equipment_requests(where: {status: {_eq: $status}}, offset: $offset, order_by: {due_date:asc}, limit: $limit) {
      equipment {
        id
        name
      }
      organization {
        name
        id
        phone
        pincode
        state
        email
        code
        city
        address
      }
      id
      status
      quantity
      due_date
      updated_at
    }
  
}
`;

export function useEquipmentRequests(filter) {
  const result = useQuery({
    query: EquipmentRequestHook,
    variables: filter,
  });
  return result;
}
