import React, { useState } from "react";
import { OrganizationEquipmentRequestsList } from "../OrganizationEquipmentRequestsList";
import { EquipmentRequestFilter } from "../../components/EquipmentRequestFilter";
const LIMIT = 10;

export function Dashboard() {
  const [filter, setFilter] = useState({
    offset: 0,
    limit: LIMIT,
    status: "open",
  });

  return (
    <>
      <EquipmentRequestFilter setFilter={setFilter} filter={filter} />
      <OrganizationEquipmentRequestsList
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
}
