import { useQuery } from "urql";
const OriganizationTypes = `
query OrganizationTypesQuery {
    organization_types{
        id
        name
    }
}
`;

export function useOrganizationTypes() {
  const result = useQuery({
    query: OriganizationTypes,
  });

  return result;
}
