import { theme } from "@chakra-ui/core";

const themeColors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000",
  white: "#fff",
  // Primary / Neutrals
  blueGray: {
    50: "#F0F4F8",
    100: "#D9E2EC",
    200: "#BCCCDC",
    300: "#9FB3C8",
    400: "#829AB1",
    500: "#627D98",
    600: "#486581",
    700: "#334E68",
    800: "#243B53",
    900: "#102A43",
  },
  lightBlueVivid: {
    50: "#E3F8FF",
    100: "#B3ECFF",
    200: "#81DEFD",
    300: "#5ED0FA",
    400: "#40C3F7",
    500: "#2BB0ED",
    600: "#1992D4",
    700: "#127FBF",
    800: "#0B69A3",
    900: "#035388",
  },
  // Supporting
  cyan: {
    50: "#E0FCFF",
    100: "#BEF8FD",
    200: "#87EAF2",
    300: "#54D1DB",
    400: "#38BEC9",
    500: "#2CB1BC",
    600: "#14919B",
    700: "#0E7C86",
    800: "#0A6C74",
    900: "#044E54",
  },
  pinkVivid: {
    50: "#FFE3EC",
    100: "#FFB8D2",
    200: "#FF8CBA",
    300: "#F364A2",
    400: "#E8368F",
    500: "#DA127D",
    600: "#BC0A6F",
    700: "#A30664",
    800: "#870557",
    900: "#620042",
  },
  redVivid: {
    50: "#FFE3E3",
    100: "#FFBDBD",
    200: "#FF9B9B",
    300: "#F86A6A",
    400: "#EF4E4E",
    500: "#E12D39",
    600: "#CF1124",
    700: "#AB091E",
    800: "#8A041A",
    900: "#610316",
  },
  yellowVivid: {
    50: "#FFFBEA",
    100: "#FFF3C4",
    200: "#FCE588",
    300: "#FADB5F",
    400: "#F7C948",
    500: "#F0B429",
    600: "#DE911D",
    700: "#CB6E17",
    800: "#B44D12",
    900: "#8D2B0B",
  },
  teal: {
    50: "#EFFCF6",
    100: "#C6F7E2",
    200: "#8EEDC7",
    300: "#65D6AD",
    400: "#3EBD93",
    500: "#27AB83",
    600: "#199473",
    700: "#147D64",
    800: "#0C6B58",
    900: "#014D40",
  },
};

export default {
  ...theme.colors,
  ...themeColors,
};
