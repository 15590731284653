import React from "react";
import ReactPaginate from "react-paginate";

export function Paginate({ offset, limit, total, onPageChange }) {
  return (
    <ReactPaginate
      pageCount={total / limit}
      initialPage={offset / limit}
      disableInitialCallback
      onPageChange={({ selected }) => {
        onPageChange(selected);
      }}
    />
  );
}
