import React from "react";
import { Stack } from "@chakra-ui/core";

export function ChainPartnerEquipmentListItem({ item }) {
  const { chain_partner } = item;

  return (
    <Stack spacing={2}>
      <div>{chain_partner.id}</div>
      <div>{chain_partner.company_name}</div>
    </Stack>
  );
}
