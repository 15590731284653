import { useQuery } from "urql";
const ChainPartnetEquipmentQuery = `
query ChainPartnerEquipmentQuery($id: Int!) {
    chain_partner_equipments(where: {equipment_id: {_eq: $id}}) {
        chain_partner {
            id
            address
            city
            state
            company_name
            email
            phone
            pincode
            website
            type
        }
    }
}
`;

export function useChainPartnersByEquipmentId({ id }) {
  const result = useQuery({
    query: ChainPartnetEquipmentQuery,
    variables: { id },
  });

  return result;
}
