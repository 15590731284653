import React from "react";
import { useForm } from "react-hook-form";
import { useOrganizationTypes } from "../../hooks";
import { FormControl, FormLabel, Input, Select, Button } from "@chakra-ui/core";
import { FormAddress } from "../shared";

export function HospitalRegistration({ onSubmit, isSubmitting }) {
  const { register, handleSubmit, errors, watch } = useForm();

  const [organizationTypesResult] = useOrganizationTypes();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.name} htmlFor="name">
          Name
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter the name of the organization"
          name="name"
          id="name"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.code} htmlFor="code">
          Code
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter the organization code"
          name="code"
          id="code"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={5}>
        <FormLabel isRequired isInvalid={errors.email} htmlFor="email">
          Email
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter the organization email"
          name="email"
          id="email"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={8}>
        <FormLabel isRequired isInvalid={errors.phone} htmlFor="phone">
          Phone
        </FormLabel>
        <Input
          type="text"
          placeholder="Enter the organization phone"
          name="phone"
          id="phone"
          ref={register({ required: true })}
        />
      </FormControl>

      <FormControl pb={8}>
        <FormLabel isRequired htmlFor="type">
          Select type
        </FormLabel>
        <Select
          id="type"
          name="organization_type_id"
          placeholder="Select type of organization"
          ref={register({ required: true })}
        >
          {organizationTypesResult.data
            ? organizationTypesResult.data.organization_types.map((type) => {
                return (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                );
              })
            : null}
        </Select>
      </FormControl>

      <FormAddress register={register} errors={errors} watch={watch} />

      <Button mt={4} variantColor="teal" isLoading={isSubmitting} type="submit">
        Submit
      </Button>
    </form>
  );
}
