import "./navbar.css";
import React from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Link, useLocation } from "react-router-dom";
import { links } from "../../utils";
import { Button } from "@chakra-ui/core";

const NavBar = () => {
  const router = useLocation();

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <header className="header">
      <Link to="/" className="logo">
        Covid Care
      </Link>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" for="menu-btn">
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        <li>
          <Link
            to={links.hospitalRequests}
            className={
              router.pathname === links.hospitalRequests ? "active" : ""
            }
          >
            Make Equipment request
          </Link>
        </li>
        <li>
          <Link
            to={links.chainPartnersEquipment}
            className={
              router.pathname === links.chainPartnersEquipment ? "active" : ""
            }
          >
            Register Equipment Supplies
          </Link>
        </li>
        <li>
          <Link href="/about">About</Link>
        </li>
        <li>
          {!isAuthenticated && (
            <Button onClick={() => loginWithRedirect({})}>Admin Log in</Button>
          )}

          {isAuthenticated && <Button onClick={() => logout()}>Log out</Button>}
        </li>
      </ul>
    </header>
  );
};

export default NavBar;
